import React from "react";
import { useOverrides } from "@quarkly/components";
import { Icon, Text, Box, Link, Image, Section } from "@quarkly/widgets";
import { MdLocationOn, MdEmail, MdPhone } from "react-icons/md";
const defaultProps = {
	"color": "--color-darkL1",
	"padding": "100px 0",
	"sm-padding": "40px 0",
	"position": "relative",
	"quarkly-title": "Form-1"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"margin": "-16px -16px -16px -16px",
			"display": "flex",
			"flex-wrap": "wrap"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"padding": "16px 16px 16px 16px",
			"width": "50%",
			"md-width": "100%"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"sm-padding": "64px 0 0 0",
			"margin": "32px 0 0 0",
			"max-width": "360px",
			"position": "relative",
			"padding": "0 0 0 64px"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"size": "48px",
			"top": "0",
			"left": "0",
			"category": "md",
			"icon": MdLocationOn,
			"position": "absolute"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"as": "h4",
			"margin": "6px 0",
			"font": "--base",
			"children": "Місцезнаходження"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"as": "p",
			"margin": "6px 0",
			"font": "--headline3",
			"children": "Львівське шосе 51, Хмельницький, 29016"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"position": "relative",
			"padding": "0 0 0 64px",
			"sm-padding": "64px 0 0 0",
			"margin": "64px 0 0 0",
			"max-width": "360px"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"top": "0",
			"left": "0",
			"category": "md",
			"icon": MdEmail,
			"position": "absolute",
			"size": "48px"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"font": "--base",
			"as": "h4",
			"margin": "6px 0",
			"children": "Email"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"as": "p",
			"margin": "6px 0",
			"font": "--headline3",
			"children": <Link href="mailto:contact@unitas-liss.com" text-decoration="none" hover-text-decoration="underline" color="--color-darkL1">
				contact@unitas-liss.com
			</Link>
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"padding": "0 0 0 64px",
			"margin": "64px 0 0 0",
			"max-width": "360px",
			"position": "relative"
		}
	},
	"icon2": {
		"kind": "Icon",
		"props": {
			"left": "0",
			"category": "md",
			"icon": MdPhone,
			"position": "absolute",
			"size": "48px",
			"top": "0"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"font": "--base",
			"as": "h4",
			"margin": "6px 0",
			"children": "Телефон"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"as": "p",
			"margin": "6px 0",
			"font": "--headline3",
			"children": "097 653 68 90"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"width": "50%",
			"padding": "8px 8px 8px 8px",
			"md-width": "100%"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://unitas-liss.com/img/4.jpg",
			"display": "block",
			"width": "100%"
		}
	},
	"box7": {
		"kind": "Box",
		"props": {}
	}
};

const Contact = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Box {...override("box2")}>
					<Box {...override("box3")}>
						<Icon {...override("icon")} />
						<Text {...override("text")} />
						<Text {...override("text1")} />
					</Box>
					<Box {...override("box4")}>
						<Icon {...override("icon1")} />
						<Text {...override("text2")} />
						<Text {...override("text3")} />
					</Box>
					<Box {...override("box5")}>
						<Icon {...override("icon2")} />
						<Text {...override("text4")} />
						<Text {...override("text5")} />
					</Box>
				</Box>
			</Box>
			<Box {...override("box6")}>
				<Image {...override("image")} />
				<Box {...override("box7")} />
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Contact, { ...Section,
	defaultProps,
	overrides
});
export default Contact;